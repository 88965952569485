import styled from '@emotion/styled'
import { AdvancedIntro } from 'app/components/AdvancedIntro'
import { Hero } from 'app/components/Hero'
import { RoomsPreview } from 'app/components/RoomsPreview'
import { SEO } from 'app/components/SEO'
import { SocialWall } from 'app/components/SocialWall'
import { Sticker } from 'app/components/Sticker'
import {
  Props as StructuredDataProps,
  StructuredData,
} from 'app/components/StructuredData'
import { Visual } from 'app/components/Visual'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { PageProps } from 'gatsby'
import React, { memo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
  footerProps?: FooterProps
  structuredDataProps?: StructuredDataProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function HomePageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  return (
    <Container>
      <SEO {...context.seoProps} />
      {context.structuredDataProps ? (
        <StructuredData
          languageCode={pageContext.languageCode}
          {...context.structuredDataProps}
        />
      ) : null}
      {context.heroProps ? (
        <Hero hasVideo variant="home" {...context.heroProps} />
      ) : null}
      {context.stickerProps ? <Sticker {...context.stickerProps} /> : null}
      {context.advancedIntroProps ? (
        <AdvancedIntro {...context.advancedIntroProps} />
      ) : null}
      {context.visualProps ? <Visual {...context.visualProps} /> : null}
      {context.roomsPreviewProps ? (
        <RoomsPreview {...context.roomsPreviewProps} />
      ) : null}
      {context.visual2Props ? <Visual {...context.visual2Props} /> : null}
      {context.paragraphProps ? (
        <AdvancedIntro variant="custom" {...context.paragraphProps} />
      ) : null}
      {context.socialWallProps ? (
        <SocialWall {...context.socialWallProps} />
      ) : null}

      {context.headerProps ? (
        <Header
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          pageID={context.id}
          flashNewsProps={
            context.flashNewsProps ? context.flashNewsProps : null
          }
          {...context.headerProps}
        />
      ) : null}
      {context.footerProps ? (
        <Footer languageCode={context.languageCode} {...context.footerProps} />
      ) : null}
    </Container>
  )
})

const Container = styled.main``
